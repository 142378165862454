import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import Button from './StartNowButton';
import { tablet, useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import checkoutSvgs from 'utils/constants/checkoutSvgs';

interface AppSectionProps {
  title: string;
  subtitle: string;
  image: string;
  list: ListProps[];
}

interface ListProps {
  title: string;
  subtitle: string;
  icon: string;
}

const AppSection: FC<AppSectionProps> = ({ title, subtitle, image, list }) => {
  const { isTablet } = useQuery();
  const renderSvgImage = (svgKey: string) => {
    const Svg = checkoutSvgs[svgKey];
    return <Svg />;
  };

  return (
    <Container>
      <Wrapper>
        <LeftSide>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          <ImageContainer>
            <Image alt="" src={image} />
          </ImageContainer>
        </LeftSide>
        <RightSide>
          {list &&
            list.map((item: ListProps, index: number) => (
              <ListContainer key={index}>
                {renderSvgImage(item.icon)}
                <ListTitle dangerouslySetInnerHTML={{ __html: item.title }} />
                <ListSubtitle>{item.subtitle}</ListSubtitle>
              </ListContainer>
            ))}
        </RightSide>
      </Wrapper>
    </Container>
  );
};

export default AppSection;

const Container = styled.div`
  padding: 3rem 1rem;
  background: #fff;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Image = styled(DynamicImage)`
  max-width: 18.625rem;
  max-height: 26.25rem;
  width: 100%;
  height: 100%;
  margin-top: 1.38rem;
  @media ${tablet} {
    width: 10.75rem;
    height: 15.375rem;
  }
`;

const RightSide = styled.div`
  display: block;
  max-width: 28.75rem;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.375rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 1rem;
  span {
    color: #ef71a0;
  }
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    padding-bottom: 0.75rem;
  }
`;

const Subtitle = styled(Text)`
  color: #000;
  font-size: 1rem;
  line-height: 1.4375rem;
  @media ${tablet} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35rem;
  width: 100%;
  @media ${tablet} {
    max-width: 28.75rem;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  :nth-child(2) {
    padding: 1.93rem 0;
    margin: 1.93rem 0;
    border-top: 1px solid #ffd9e7;
    border-bottom: 1px solid #ffd9e7;
  }
  @media ${tablet}{
    :nth-child(2) {
      padding: 2rem 0;
      margin: 2rem 0;
  }
`;

const ListTitle = styled(Text)`
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1rem 0 0.5rem;
  line-height: 2rem;
  span {
    color: #ef71a0;
  }
  @media ${tablet} {
    padding: 0.75rem 0 0.25rem;
  }
`;

const ListSubtitle = styled(Text)`
  color: #000;
  font-size: 1rem;
  line-height: 1.4375rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: normal;
  }
`;

const ImageContainer = styled.div`
  max-width: 24.23438rem;
  width: 100%;
  @media ${tablet} {
    max-width: 10.75rem;
  }
`;
