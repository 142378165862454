export const getDateLanguageString = (chartLanguage?: string) => {
  switch (chartLanguage) {
    case 'es':
      return 'es-ES';
    case 'en':
      return 'en-US';
    default:
      return 'en-US';
  }
};
