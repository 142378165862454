import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import StartNowButton from './StartNowButton';
import { mobile, sxMobile, tablet, useQuery } from 'styles/breakpoints';
import { getDateLanguageString } from 'utils/dates';
import resultsSvgs from 'utils/constants/resultsSvgs';

interface TextImageCardButtonProps {
  title: string;
  image: string;
  chartImage: string;
  chartLanguage?: string;
  chartImage_mob: string;
  image_mob: string;
  card: {
    boldText: string;
    mainText: string;
    svg: string;
  };
  buttonText: string;
  onClick: () => void;
  loading?: boolean;
}

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;
const MonthsLine = styled.div`
  position: absolute;
  right: 30px;
  bottom: -28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 83%;
  max-width: 460px;
  @media ${mobile} {
    bottom: -16px;
    max-width: 260px;
  }
`;

const TextStyled = styled(Text)`
  color: #73899c;
  font-size: 0.67056rem;
  @media ${mobile} {
    font-size: 0.5rem;
  }
`;

const currentDate = new Date();
const currentMonth = currentDate.toLocaleString(getDateLanguageString(), {
  month: 'long',
});

const nextMonth = new Date();
nextMonth.setMonth(currentDate.getMonth() + 1);

const thirdMonth = new Date();

thirdMonth.setMonth(currentDate.getMonth() + 2);

const TextImageCardButton: FC<TextImageCardButtonProps> = ({
  title,
  image,
  image_mob,
  chartImage,
  chartLanguage,
  chartImage_mob,
  card,
  buttonText,
  onClick,
  loading,
}) => {
  const { isMobile } = useQuery();
  const renderSvgImage = (svgKey: string) => {
    const Svg = resultsSvgs[svgKey];
    return <Svg />;
  };
  return (
    <>
      <Container>
        <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
        {image && <StyledImage src={image} alt="chart_image" />}
        {chartImage && (
          <ImageWrapper>
            <StyledImage src={chartImage} alt="chart_image" />
            <MonthsLine>
              <TextStyled type={isMobile ? 'bodyXS' : 'bodyS'}>
                {currentMonth}
              </TextStyled>
              <TextStyled type={isMobile ? 'bodyXS' : 'bodyS'}>
                {nextMonth.toLocaleString(
                  getDateLanguageString(chartLanguage),
                  {
                    month: 'long',
                  },
                )}
              </TextStyled>
              <TextStyled type={isMobile ? 'bodyXS' : 'bodyS'}>
                {thirdMonth.toLocaleString(
                  getDateLanguageString(chartLanguage),
                  {
                    month: 'long',
                  },
                )}
              </TextStyled>
            </MonthsLine>
          </ImageWrapper>
        )}
        <Card>
          <CardBoldText>
            {card.boldText} {renderSvgImage(card.svg)}
          </CardBoldText>
          <CardMainText>{card.mainText}</CardMainText>
        </Card>
        <StyledButton onClick={onClick} loading={loading}>
          {buttonText}
        </StyledButton>
      </Container>
    </>
  );
};

export default TextImageCardButton;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  max-width: 30.75rem;
  margin: 0 auto;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const StyledTitle = styled.h1`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.375rem;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const StyledImage = styled(DynamicImage)`
  width: 100%;
  width: 28.75rem;
  @media ${tablet} {
    height: 100%;
    width: 100%;
    width: 28.75rem;
  }

  @media ${mobile} {
    width: 21.4375rem;
  }

  @media ${sxMobile} {
    width: 18.4375rem;
  }
`;

const Card = styled.div`
  display: flex;
  max-width: 28.75rem;
  width: 100%;
  padding: 0.75rem 1.6875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #7bb2c6;
  background: #f3fafb;
  margin-top: 1.5rem;
`;

const CardBoldText = styled.p`
  color: #7bb2c6;
  text-align: center;
  font-size: 3rem;
  font-weight: 800;
  line-height: 4rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const CardMainText = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  line-height: 1.4375rem;
`;

const StyledButton = styled(StartNowButton)`
  margin-top: 1.5rem;
  max-width: 30.75rem;
  width: 100%;
  border-radius: 100px;
  height: 56px;
  @media ${mobile} {
    margin-top: 1rem;
  }
`;
