import React, { useCallback } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';
import resultsSvgs from 'utils/constants/resultsSvgs';
import { useSelector } from 'react-redux';
import { textSpanContainsPosition } from 'typescript';
import { AppState } from 'state/types';

interface ListItem {
  text: string;
}

interface IconListProps {
  title?: string;
  cardTitle?: string;
  imageSvg?: string;
  icon: string;
  list: ListItem[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30.75rem;
  padding: 3rem 1rem;
  gap: 1.5rem;
  margin: 0 auto;
  @media ${tablet} {
    gap: 1rem;
    padding: 2.25rem 1rem;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.75rem;
  flex-direction: row;
  gap: 0.75rem;
  color: #000;
  font-size: 1rem;
  line-height: 1.4375rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.375rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const CardTitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2rem;
  padding: 0.75rem 0;
`;

const Card = styled.div`
  background: #faf1eb;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.5rem;
  @media ${tablet} {
    padding: 1.5rem 0.688rem;
  }
`;

const SvgConatiner = styled.div`
  width: 1.5rem;
  height: 1rem;
`;

const IconList: FC<IconListProps> = ({
  title,
  list,
  cardTitle,
  imageSvg,
  icon,
}) => {
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);

  const renderSvgImage = (svgKey: string) => {
    const Svg = resultsSvgs[svgKey];
    return <Svg />;
  };

  const renderBabyGender = text => {
    if (!quizAnswers) return null;
    const regex = /\${gender}/;
    const gender = quizAnswers?.gender;
    const replacedString = text.replace(regex, gender);
    return replacedString;
  };
  const renderItem = useCallback(
    (item: ListItem, index: number) => (
      <ItemContainer key={index}>
        <SvgConatiner>{renderSvgImage(icon)}</SvgConatiner>
        {renderBabyGender(item)}
      </ItemContainer>
    ),
    [],
  );

  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Card>
        {imageSvg && (
          <ImageContainer>{renderSvgImage(imageSvg)}</ImageContainer>
        )}
        <CardTitle>{cardTitle}</CardTitle>
        {list.map(renderItem)}
      </Card>
    </Container>
  );
};

export default React.memo(IconList);
